/********** Template CSS **********/
:root {
  --primary: #009CFF;
  --light: #F3F6F9;
  --dark: #191C24;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 45px;
  bottom: 45px;
  z-index: 99;
}


/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity .5s ease-out, visibility 0s linear .5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity .5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}


/*** Button ***/
.btn {
  transition: .5s;
}

.btn.btn-primary {
  color: #FFFFFF;
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  border-radius: 50px;
}


/*** Layout ***/
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 202px;
  height: 100vh;
  overflow-y: auto;
  background: var(--light);
  transition: 0.5s;
  z-index: 999;
}

.content {
  margin-left: 209px;
  background: #FFFFFF;
  transition: 0.5s;
}

@media (min-width: 992px) {
  .sidebar {
    margin-left: 0;
  }

  .sidebar.open {
    margin-left: -250px;
  }

  /* .content {
    width: calc(100% - 250px);
  } */

  .content.open {
    width: 100%;
    margin-left: 0;
  }
}

@media (max-width: 991.98px) {
  .sidebar {
    margin-left: -250px;
  }

  .sidebar.open {
    margin-left: 0;
  }

  .content {
    width: 100%;
    margin-left: 0;
  }
}


/*** Navbar ***/
.sidebar .navbar .navbar-nav .nav-link {
  padding: 7px 20px;
  color: var(--dark);
  font-weight: 500;
  border-left: 3px solid var(--light);
  border-radius: 0 30px 30px 0;
  outline: none;
}

.sidebar .navbar .navbar-nav .nav-link:hover,
.sidebar .navbar .navbar-nav .nav-link.active {
  color: var(--primary);
  background: #FFFFFF;
  border-color: var(--primary);
}

.sidebar .navbar .navbar-nav .nav-link i {
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  border-radius: 40px;
}

.sidebar .navbar .navbar-nav .nav-link:hover i,
.sidebar .navbar .navbar-nav .nav-link.active i {
  background: var(--light);
}

.sidebar .navbar .dropdown-toggle::after {
  position: absolute;
  top: 15px;
  right: 15px;
  border: none;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  transition: .5s;
}

.sidebar .navbar .dropdown-toggle[aria-expanded=true]::after {
  transform: rotate(-180deg);
}

.sidebar .navbar .dropdown-item {
  padding-left: 41px;
  border-radius: 0 33px 33px 0;
}

.content .navbar .navbar-nav .nav-link {
  margin-left: 25px;
  padding: 12px 0;
  color: var(--dark);
  outline: none;
}

.content .navbar .navbar-nav .nav-link:hover,
.content .navbar .navbar-nav .nav-link.active {
  color: var(--primary);
}

.content .navbar .sidebar-toggler,
.content .navbar .navbar-nav .nav-link i {
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  border-radius: 40px;
}

.content .navbar .dropdown-toggle::after {
  margin-left: 6px;
  vertical-align: middle;
  border: none;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  transition: .5s;
}

.content .navbar .dropdown-toggle[aria-expanded=true]::after {
  transform: rotate(-180deg);
}

@media (max-width: 575.98px) {
  .content .navbar .navbar-nav .nav-link {
    margin-left: 15px;
  }
}


/*** Date Picker ***/
.bootstrap-datetimepicker-widget.bottom {
  top: auto !important;
}

.bootstrap-datetimepicker-widget .table * {
  border-bottom-width: 0px;
}

.bootstrap-datetimepicker-widget .table th {
  font-weight: 500;
}

.bootstrap-datetimepicker-widget.dropdown-menu {
  padding: 10px;
  border-radius: 2px;
}

.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
  background: var(--primary);
}

.bootstrap-datetimepicker-widget table td.today::before {
  border-bottom-color: var(--primary);
}


/*** Testimonial ***/
.progress .progress-bar {
  width: 0px;
  transition: 2s;
}


/*** Testimonial ***/
.testimonial-carousel .owl-dots {
  margin-top: 24px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.testimonial-carousel .owl-dot {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  width: 15px;
  height: 15px;
  border: 5px solid var(--primary);
  border-radius: 15px;
  transition: .5s;
}

.testimonial-carousel .owl-dot.active {
  background: var(--dark);
  border-color: var(--primary);
}

body {
  background-color: #FFFFFF !important;
}

table {
  font-size: 13px;
}

form-control:disabled,
.form-control:read-only {
  background: none !important;
}

.btn-link {
  font-size: 13px !important;
}

.add_link {
  font-size: 18px !important;
}


.skeleton-wrapper {
  padding: 20px;
}

.skeleton-table {
  width: 100%;
  border-collapse: collapse;
}

.skeleton-row {
  display: flex;
  padding: 10px 0;
  border-bottom: 1px solid #e0e0e0;
}

.skeleton-cell {
  flex: 1;
  height: 20px;
  margin: 0 5px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}

.btn-link {
  font-weight: 500;
  color: #263275 !important;
  font-size: 12px !important;
}

.add_link {
  font-size: 11px;
  color: #263275;
}

.action_link {
  font-size: 14px !important;
}

.sc_logo {
  font-size: 36px;
}

@media print {

  /* Hide the div with a specific ID */
  .navbar {
    display: none !important;
  }

  /* Hide the button with a specific class */
  .print-button {
    display: none !important;
  }

  .date-selector {
    display: none !important;
  }

  .no-print {
    display: none !important;
  }

  .print-section {
    page-break-before: always;
  }

}

.blue-input-container input,
.blue-input-container select,
.blue-input-container textarea {
  color: blue;
}

.file_bulck {
  font-size: 18px !important;
}

/* @keyframes blink {
  0% {
    background-color: inherit;
  }

  50% {
    background-color: rgb(198, 116, 116);
  }

  100% {
    background-color: inherit;
  }
} */

.blink {
  animation: blink 1s infinite !important;
}

.background-blink {
  animation: background-blink 1s linear infinite;
  -webkit-animation: background-blink 1s linear infinite;
  /* For older browsers */
}

.highlight {
  background-color: rgb(83, 154, 215);
  /* Adjust as per your highlight style */
}

.highlight td {
  color: rgb(233, 233, 235);
}

.highlight-annual {
  background-color: rgb(135, 97, 97);
  color: white !important;
}

.round-icon {
  display: inline-block;
  width: 20px;
  /* Adjust size as needed */
  height: 20px;
  /* Adjust size as needed */
  background-color: red;
  /* Adjust background color as needed */
  color: white;
  /* Adjust text color as needed */
  text-align: center;
  line-height: 20px;
  /* Adjust line height to match the height */
  border-radius: 50%;
  font-weight: bold;
}

.modal-body {
  max-height: 505px;
  overflow-y: auto;
}

.trash-bulk {
  font-size: 20px;
}

.pagination {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.page-item {
  margin: 0 2px;
}

.page-link {
  display: block;
  padding: 8px 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  text-decoration: none;
  color: #007bff;
}

.page-link:hover {
  background-color: #f1f1f1;
}

.active .page-link {
  background-color: #007bff;
  color: white;
}


.pagination {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.pagination button {
  margin: 0 5px;
  padding: 10px 15px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.pagination button:hover {
  background-color: #0056b3;
}

.pagination button.active {
  background-color: #0056b3;
  font-weight: bold;
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.multi-color-move {
  display: inline-block;
  padding: 10px;
  /* Add padding */
  border-radius: 5px;
  /* Optional: rounded corners */
  animation: move-up-down 1.5s linear infinite;
  background: linear-gradient(45deg, #ff6b6b, #f7d94c, #6bff6b, #6bb3ff);
  /* Gradient colors */
  background-size: 400% 400%;
  /* For smooth animation */
  color: white;
  /* Text color */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  /* Optional: text shadow for better visibility */
}

@keyframes move-up-down {
  0% {
    transform: translateY(0);
    background-position: 0% 50%;
    /* Start position of the gradient */
  }

  50% {
    transform: translateY(-5px);
    background-position: 100% 50%;
    /* Mid position of the gradient */
  }

  100% {
    transform: translateY(0);
    background-position: 0% 50%;
    /* End position of the gradient */
  }
}